import {
  BenefitsUsed,
  Destination,
  Preference,
  UserInformation,
} from './types/vivaaerobus';
import animationDataOne from './lotties/badges-end/01_V_Consciente_H.json';
import animationDataTwo from './lotties/badges-end/02_V_Internacional_H.json';
import animationDataThree from './lotties/badges-end/03_V_Experto_H.json';
import animationDataFour from './lotties/badges-end/04_V_Incasable_H.json';
import animationDataFive from './lotties/badges-end/05_V_Ganapuntos_H.json';
import animationDataSix from './lotties/badges-end/06_V_Ahorrador_H.json';
import animationDataSeven from './lotties/badges-end/07_V_Smart_H.json';
import animationDataEight from './lotties/badges-end/08_V_OnTheGo_H.json';
import animationDataNine from './lotties/badges-end/09_Aficionado_H.json';
import animationDataTen from './lotties/badges-end/10_V_VIP.json';
import animationDataEleven from './lotties/badges-end/11_V_Playero.json';
import { OnTheGoSVG } from './components/badges/onTheGo.component';
import { ConscienteSVG } from './components/badges/consciente.component';
import { EquipadoSVG } from './components/badges/equipado.component';
import { GanaPuntosSVG } from './components/badges/ganaPuntos.component';
import { VipSVG } from './components/badges/vip.component';
import { CruzaFronterasSVG } from './components/badges/cruzaFronteras.component';
import { AhorradorSVG } from './components/badges/ahorrador.component';
import { InalcansableSVG } from './components/badges/inalcansable.component';
import { ExpertoSVG } from './components/badges/experto.component';
import { AficionadoSVG } from './components/badges/aficionado.component';
import { PlayeroSVG } from './components/badges/playero.component';


const getTotalBundles = (preferences: Preference[]) => {
  const bundleTypes: string[] = [];
  preferences.forEach((preference) => {
    const { bundle } = preference;
    bundle.forEach((b) => {
      if (b.name !== null) {
        bundleTypes.push(b.name.toLocaleLowerCase());
      }
    });
  });
  const uniqueBundleTypes = [...new Set(bundleTypes)];
  const uniqueBundleTypesWithCount = uniqueBundleTypes.map((bundleType) => {
    const count = bundleTypes.filter((type) => type === bundleType).length;
    return { bundleType, count };
  });
  return uniqueBundleTypesWithCount;
};

const getDestinationTypes = (preferences: Preference[]) => {
  const destinationTypes: string[] = [];
  preferences.forEach((preference) => {
    const { destinations } = preference;
    if (destinations.length) {
      destinationTypes.push(destinations[0].type);
    }
  });
  const uniqueDestinationTypes = [...new Set(destinationTypes)];
  const uniqueDestinationTypesWithCount = uniqueDestinationTypes.map(
    (destinationType) => {
      const count = destinationTypes.filter(
        (type) => type === destinationType
      ).length;
      return { destinationType, count };
    }
  );
  return uniqueDestinationTypesWithCount;
};

const hasInternational = (
  destinationTypes: { destinationType: string; count: number }[]
) => {
  const validTypes = ['Latinoamericano', 'US Amigos y Familia', 'Caribe'];
  const international = destinationTypes.find((d) =>
    validTypes.includes(d.destinationType)
  );

  return !!international;
};

const hasBeach = (
  destinationTypes: { destinationType: string; count: number }[]
) => {
  const validTypes = ['Playa'];
  const beach = destinationTypes.find((d) =>
    validTypes.includes(d.destinationType)
  );

  return !!beach;
};

const getSeatsTypes = (preferences: Preference[]) => {
  const seatsTypes: string[] = [];
  preferences.forEach((preference) => {
    const { seats } = preference;
    if (seats.brand) {
      seatsTypes.push(seats.brand.toLocaleLowerCase());
    }
  });
  const uniqueSeatsTypes = [...new Set(seatsTypes)];
  const uniqueSeatsTypesWithCount = uniqueSeatsTypes.map((seatsType) => {
    const count = seatsTypes.filter((type) => type === seatsType).length;
    return { seatsType, count };
  });
  return uniqueSeatsTypesWithCount;
};

const getTotalBenefitsUsed = (benefitsUsed: BenefitsUsed[]) => {
  const totalBenefitsUsed = {
    doters: { pointsAcumulation: 0, pointsUsed: 0 },
    vivaFan: { pointsAcumulation: 0, pointsUsed: 0 },
    smileAndFly: { pointsAcumulation: 0, pointsUsed: 0 },
  };
  benefitsUsed.forEach((benefitUsed) => {
    const { doters, vivaFan, smileAndFly } = benefitUsed;
    [
      { benefitName: 'doters', benefit: doters },
      { benefitName: 'vivaFan', benefit: vivaFan },
      { benefitName: 'smileAndFly', benefit: smileAndFly },
    ].forEach((benefitData) => {
      if (benefitData.benefit) {
        const { pointsAcumulation, pointsUsed } = benefitData.benefit.reduce(
          (acc, curr) => {
            return {
              pointsAcumulation: acc.pointsAcumulation + curr.pointsAcumulation,
              pointsUsed: acc.pointsUsed + curr.pointsUsed,
            };
          }
        );
        (totalBenefitsUsed as any)[benefitData.benefitName].pointsAcumulation +=
          pointsAcumulation;
        (totalBenefitsUsed as any)[benefitData.benefitName].pointsUsed +=
          pointsUsed;
      }
    });
  });
  return totalBenefitsUsed;
};

const getTotalChannels = (preferences: Preference[]) => {
  const channels: string[] = [];
  preferences.forEach((preference) => {
    if (!preference.channel) return;
    const { channel } = preference;
    channels.push(channel.toLocaleLowerCase());
  });
  const uniqueChannels = [...new Set(channels)];
  const uniqueChannelsWithCount = uniqueChannels.map((channel) => {
    const count = channels.filter((type) => type === channel).length;
    return { channel, count };
  });
  return uniqueChannelsWithCount;
};

const getTotalFlights = (preferences: Preference[]) => {
  const totalDestinations: Destination[] = [];
  preferences?.forEach((preference: Preference) => {
    const { destinations } = preference;
    destinations?.forEach((destination: Destination) => {
      totalDestinations.push(destination);
    });
  });
  return totalDestinations.length;
};

const getTotalPromotionalBookings = (preferences: Preference[]) => {
  const totalPromotionalBookings = preferences
    .filter((preference) => preference.promotionalBookings)
    .reduce((acc, curr) => acc + curr.promotionalBookings, 0);
  return totalPromotionalBookings;
};

const calculateBadges = (customer: Partial<UserInformation>) => {
  const { carbonFootprints, preferences, segmentsFlown } = customer;
  const totalFlights = segmentsFlown || 0; // getTotalFlights(preferences || []);
  const totalBundles = getTotalBundles(preferences || []);
  const totalPromotionalBookings = getTotalPromotionalBookings(
    preferences || []
  );
  const totalDestinations = getDestinationTypes(preferences || []);
  const totalSeats = getSeatsTypes(preferences || []);
  const totalBenefitsUsed = getTotalBenefitsUsed(customer.benefitsUsed || []);
  const totalChannels = getTotalChannels(preferences || []);

  /*console.log({
    carbonFootprints,
    totalFlights,
    totalBundles,
    totalPromotionalBookings,
    totalDestinations,
    totalSeats,
    totalBenefitsUsed,
    totalChannels,
  });*/

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    !totalPromotionalBookings &&
    !hasInternational(totalDestinations) &&
    !hasBeach(totalDestinations) &&
    !totalSeats.find((s) => (s.seatsType || '').includes('vip')) &&
    totalBenefitsUsed.doters.pointsAcumulation < 10000 &&
    totalChannels.find((c) => c.channel.includes('app'))
  ) {
    return {
      animationData: animationDataEight,
      description: 'On the go',
      title: 'Eres Viajer@ On the go',
      image: OnTheGoSVG,
      imagePath: '/assets/images/background/end-scenes/end-5.jpg',
      label:
        'Según mis vuelos, mis destinos y <strong>mis habilidades digitales para reservar</strong>',
    };
  }

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    !totalPromotionalBookings &&
    !hasInternational(totalDestinations) &&
    !hasBeach(totalDestinations) &&
    !totalSeats.find((s) => (s.seatsType || '').includes('vip')) &&
    totalBenefitsUsed.doters.pointsAcumulation < 10000 &&
    totalBundles.find((b) => b.bundleType.includes('smart'))
  ) {
    return {
      animationData: animationDataSeven,
      description: 'Equipad@',
      title: 'Eres Viajer@ Equipad@',
      image: EquipadoSVG,
      imagePath: '/assets/images/background/end-scenes/end-4.jpg',
      label:
        'Según mis vuelos, mis destinos y <strong>mi forma de viajar con todo listo</strong>',
    };
  }

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    !totalPromotionalBookings &&
    !hasInternational(totalDestinations) &&
    !hasBeach(totalDestinations) &&
    !totalSeats.find((s) => (s.seatsType || '').includes('vip')) &&
    totalBenefitsUsed.doters.pointsAcumulation >= 22000
  ) {
    return {
      animationData: animationDataFive,
      description: 'Gana Puntos',
      title: 'Eres Viajer@ Ganapuntos',
      image: GanaPuntosSVG,
      imagePath: '/assets/images/background/end-scenes/end-7.jpg',
      label: 'Según mis vuelos, mis destinos y <strong>mi adicción a los Puntos Doters</strong>',
    };
  }

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    !totalPromotionalBookings &&
    !hasInternational(totalDestinations) &&
    !hasBeach(totalDestinations) &&
    totalSeats.find((s) => (s.seatsType || '').includes('vip'))
  ) {
    return {
      animationData: animationDataTen,
      description: 'VIP',
      title: 'Eres Viajer@ VIP',
      image: VipSVG,
      imagePath: '/assets/images/background/end-scenes/end-4.jpg',
      label:
        'Según mis vuelos, mis destinos y <strong>mi forma de viajar con espacio extra</strong>',
    };
  }

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    !totalPromotionalBookings &&
    hasInternational(totalDestinations)
  ) {
    return {
      animationData: animationDataTwo,
      description: 'Cruzafronteras',
      title: 'Eres Viajer@ Cruzafronteras',
      image: CruzaFronterasSVG,
      imagePath: '/assets/images/background/end-scenes/end-3.jpg',
      label: 'Según mis vuelos, mis destinos y <strong>los sellos de mi pasaporte</strong>',
    };
  }

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    !totalPromotionalBookings &&
    hasBeach(totalDestinations)
  ) {
    return {
      animationData: animationDataEleven,
      description: 'Player@',
      title: 'Eres Viajer@ Player@',
      image: PlayeroSVG,
      imagePath: '/assets/images/background/end-scenes/end-6.jpg',
      label: 'Según mis vuelos, mis destinos y <strong>mi amor por el sol y la arena</strong>',
    };
  }

  if (
    totalFlights < 14 &&
    !carbonFootprints &&
    totalPromotionalBookings > 0 &&
    totalPromotionalBookings <= 7
  ) {
    return {
      animationData: animationDataSix,
      description: 'Ahorrador/a',
      title: 'Eres Viajer@ Ahorrador/a',
      image: AhorradorSVG,
      imagePath: '/assets/images/background/end-scenes/end-7.jpg',
      label:
        'Según mis vuelos, mis destinos y <strong>por mi habilidad para encontrar ofertas</strong>',
    };
  }

  if (totalFlights < 14 && carbonFootprints && carbonFootprints < 11) {
    return {
      animationData: animationDataOne,
      description: 'Consciente',
      title: 'Eres Viajer@ Consciente',
      image: ConscienteSVG,
      imagePath: '/assets/images/background/end-scenes/end-1.jpg',
      label:
        'Según mis viajes, mis destinos y <strong>mis vuelos con compensaciones de CO2</strong>',
    };
  }

  if (totalFlights > 14 && totalFlights < 30) {
    return {
      animationData: animationDataFour,
      description: 'Incansable',
      title: 'Eres Viajer@ Incansable',
      image: InalcansableSVG,
      imagePath: '/assets/images/background/end-scenes/end-2.jpg',
      label: 'Según mis vuelos, mis destinos y <strong>por volar muchísimo este año</strong>',
    };
  }

  if (totalFlights >= 30) {
    return {
      animationData: animationDataThree,
      description: 'Expert@',
      title: 'Eres Viajer@ Expert@',
      image: ExpertoSVG,
      imagePath: '/assets/images/background/end-scenes/end-5.jpg',
      label: 'Según mis vuelos, mis destinos y <strong>por volar como pocos este año</strong>',
    };
  }

  return {
    animationData: animationDataNine,
    description: 'Aficionad@',
    title: 'Eres aficionad@ a los vuelos',
    image: AficionadoSVG,
    imagePath: '/assets/images/background/end-scenes/end-4.jpg',
    label: 'Según mis vuelos, mis destinos y <strong>mi forma de viajar</strong>',
  };
};

const totalDotersPointsAccumulation = (benefitsUsed: BenefitsUsed[]) => {
  if (!benefitsUsed)
    return {
      dotersPointsAcumulation: 0,
      dotersPointsUsed: 0,
    };

  const dotersPointsAcumulation = benefitsUsed
    .map((benefit) => {
      if (!benefit || !benefit.doters) return 0;
      const { doters } = benefit;
      const pointsAcumulation = doters
        .map((doter) => {
          return doter.pointsAcumulation;
        })
        .reduce((a, b) => a + b, 0);
      return pointsAcumulation;
    })
    .reduce((a, b) => a + b, 0);

  const dotersPointsUsed = benefitsUsed
    .map((benefit) => {
      if (!benefit || !benefit.doters) return 0;
      const { doters } = benefit;
      const pointsUsed = doters
        .map((doter) => {
          return doter.pointsUsed;
        })
        .reduce((a, b) => a + b, 0);
      return pointsUsed;
    })
    .reduce((a, b) => a + b, 0);

  return { dotersPointsAcumulation, dotersPointsUsed };
};

const getBenefitsUsedTypes = (benefitsUsed: BenefitsUsed[]) => {
  const benefitsUsedTypes: string[] = [];
  benefitsUsed.forEach((benefitUsed) => {
    const keys = Object.keys(benefitUsed);
    keys.forEach((key) => {
      if (key !== 'id') {
        benefitsUsedTypes.push(key);
      }
    });
  });
  const uniqueBenefitsUsedTypes = [...new Set(benefitsUsedTypes)];
  return uniqueBenefitsUsedTypes;
};

const getTotalSmileAndFly = (benefitsUsed: BenefitsUsed[]) => {
  let total = 0;
  benefitsUsed.forEach((benefitUsed) => {
    const vivaFan = benefitUsed.vivaFan || [];
    vivaFan.forEach((v) => {
      const smileAndFly = v.smileAndFly || 0;
      total += smileAndFly;
    });
  });

  return total;
};

const getSeatBrands = (preferences: Preference[]) => {
  const seatsBrand: string[] = [];
  preferences.forEach((preference) => {
    const { seats } = preference;
    if (seats.brand) {
      seatsBrand.push(seats.brand);
    }
  });
  const uniqueSeatsBrand = [...new Set(seatsBrand)];
  const uniqueSeatsBrandWithCount = uniqueSeatsBrand.map((brand) => {
    const count = seatsBrand.filter((type) => type === brand).length;
    return { brand, count };
  });
  return uniqueSeatsBrandWithCount;
};

export default {
  getTotalFlights,
  calculateBadges,
  totalDotersPointsAccumulation,
  getBenefitsUsedTypes,
  getTotalSmileAndFly,
  getSeatBrands,
};
