import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/23_MundoVerde.json';
import animationDataTwo from '../../../lotties/51_Maleta.json';
import animationDataThree from '../../../lotties/33_Bandera.json';
import { Preference, UserInformation } from '../../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const SceneChannel = ({ customer: { preferences } }: Props) => {
  const [ticketsByApp, setTicketsByApp] = useState(0);
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationThree = {
    loop: true,
    autoplay: true,
    animationData: animationDataThree,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getTicketsByApp = (preferences: Preference[]) => {
    if (!preferences) return 0;

    const ticketsByApp = (
      preferences.map((preference) => {
        return preference.channel === 'App' ? 1 : 0;
      }) as number[]
    ).reduce((a, b) => a + b, 0);

    return ticketsByApp;
  };

  useEffect(() => {
    const ticketsByApp = getTicketsByApp(preferences || []);
    setTicketsByApp(ticketsByApp);
  }, [preferences]);

  return ticketsByApp ? (
    <>
      <section className="scene scene--color-4">
        <div className="container">
          <div className="row channel-row">
            <div className="badge-greenLight text-center">
              <p className="h3 white-text">
                Armando viajes desde cualquier lugar
              </p>
            </div>
            <div className="maleta">
              <div className="lottie-player">
                <Lottie options={animationTwo} />
              </div>
            </div>
            <div className="device">
              <figure className="device__item">
                <img
                  src="/assets/images/elements/device.png"
                  alt="Dispositivo"
                />
              </figure>
              <article className="device__article text-center">
                <div>
                  <p className="h4 green-text">Este año hiciste</p>
                  <p className="h3 greenLight-text">la mayoría</p>
                  <p className="h3 greenLight-text">de tus reservas</p>
                  <p className="h4 green-text">a través de la App de Viva.</p>
                </div>
              </article>
              <div className="device__image green-world--small">
                <div className="lottie-player">
                  <Lottie options={animationOne} />
                </div>
              </div>
              <div className="device__image viva-flag">
                <div className="lottie-player">
                  <Lottie options={animationThree} />
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default SceneChannel;
