
import React from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../lotties/loader.json';


const LoaderComponent = () => {
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <div className="loader-element">
        <div className="loader-element__container">
          <div className="lottie-player ">
              <Lottie options={animationOne} />
            </div>
        </div>
      </div>
    </>
  )
    
};

export default LoaderComponent;
