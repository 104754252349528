/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SceneCarbono from '../../components/scenes/sceneCarbono/sceneCarbono.component';
import SceneChannel from '../../components/scenes/sceneChannel/sceneChannel.component';
import SceneDestiny from '../../components/scenes/sceneDestiny/sceneDestiny.component';
import SceneDoters from '../../components/scenes/sceneDoters/sceneDoters.component';
import SceneEnd from '../../components/scenes/sceneEnd/sceneEnd.component';
import SceneFlights from '../../components/scenes/sceneFlights/sceneFlights.component';
import SceneForm from '../../components/scenes/sceneForm/sceneForm.component';
import SceneKMTime from '../../components/scenes/sceneKMTime/sceneKMTime.component';
import SceneMembership from '../../components/scenes/sceneMembership/sceneMembership.component';
import SceneMode from '../../components/scenes/sceneMode/sceneMode.component';
import ScenePlace from '../../components/scenes/scenePlace/scenePlace.component';
import ScenePlaceSet from '../../components/scenes/scenePlaceSet/sceneSet.component';
import ScenePlane from '../../components/scenes/scenePlane/scenePlane.component';
import SceneSale from '../../components/scenes/sceneSale/sceneSale.component';
import SceneSmile from '../../components/scenes/sceneSmile/sceneSmile.component';
import SceneSubEnd from '../../components/scenes/sceneSubEnd/sceneSubEnd.component';
import HeaderComponent from '../../components/shared/header/header.component';
import { UserInformation } from '../../types/vivaaerobus';
import { getUserInfo } from '../../api/vivaaerobus';
import LoaderComponent from '../../components/loader/loader.component';
import SceneInit from '../../components/scenes/sceneInit/sceneInit.component';
import ScenePrepare from '../../components/scenes/scenePrepare/scenePrepare.component';
import SceneEmpty from '../../components/scenes/sceneEmpty/sceneEmpty.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SceneFinish from '../../components/scenes/sceneFinish/sceneFinish.component';
import helpers from '../../helpers';



const HomeScreen = () => {
  const { customerId, identityId } = useParams();
  const [customer, setCustomer] = useState<Partial<UserInformation>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [dotersPointsAcumulation, setDotersPointsAcumulation] = useState(0);
  const [totalSmileAndFly, setTotalSmileAndFly] = useState(0);

  useEffect(() => {
    if (!customerId || !identityId) {
      setIsLoading(false);
      return;
    }

    getUserInfo(customerId, identityId)
      .then((response) => {
        if (response && response.data) {
          setCustomer(response.data);
          const { dotersPointsAcumulation } =
            helpers.totalDotersPointsAccumulation(response.data.benefitsUsed);
          setDotersPointsAcumulation(dotersPointsAcumulation);
          const totalSmileAndFly = helpers.getTotalSmileAndFly(
            response.data.benefitsUsed
          );
          setTotalSmileAndFly(totalSmileAndFly);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Ocultar el loader después de cargar los datos
      });
  }, [customerId, identityId]);


  const handleOrientationChange = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange);

    // Limpieza
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: "ev_ux",
      category: "lp_desempacando2023",
      action: "first_step",
      label: "open"
    });
    //console.log('INIT', window.dataLayer.push)
  }, []);

  return (
    <>
      {isLoading ? (
        <LoaderComponent /> // Mostrar el loader mientras carga
      ) : Object.keys(customer).length > 0 ? (
        <>
         
          <HeaderComponent />
        <SceneInit customer={customer} />
          <SceneFlights customer={customer} />
          <SceneDestiny customer={customer} />
          <SceneKMTime customer={customer} />
          <SceneCarbono customer={customer} />
          <SceneSale customer={customer} />
          {dotersPointsAcumulation > 10000 && (
            <SceneDoters customer={customer} />
          )}
          <SceneMode customer={customer} />
          {totalSmileAndFly ? <SceneSmile /> : <></>}
          <ScenePlace customer={customer} />
          <ScenePlaceSet customer={customer} />
          <ScenePlane customer={customer} />
          <SceneChannel customer={customer} />
          <SceneMembership customer={customer} />
          <SceneSubEnd />
          <SceneEnd customer={customer} />
          <ScenePrepare />
          <SceneForm customer={customer} /> 
        </>
      ) : (
        <>
          <HeaderComponent />
          <SceneFinish />
        </>
      )}
      {/* Habilitar una función para que el día que se acabe la campaña solo meter esta vista que es  OFF  <SceneFinish /> */}
    </>
  );
};

export default HomeScreen;
