import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/58_Medalla320.json';
import animationDataTwo from '../../../lotties/50_Avion_321.json';
import animationDataThree from '../../../lotties/58_Medalla321.json';
import animationDataFour from '../../../lotties/50_Avion_320.json';
import { Preference, UserInformation } from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface Props {
  customer: Partial<UserInformation>;
}

const ScenePlane = ({ customer: { preferences } }: Props) => {
  const [showSection, setShowSection] = useState(true);
  const [aircraftType, setAircraftType] = useState({
    type: '',
    count: 0,
  });
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationThree = {
    loop: true,
    autoplay: true,
    animationData: animationDataThree,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationFour = {
    loop: true,
    autoplay: true,
    animationData: animationDataFour,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getAircraftType = (preferences: Preference[]) => {
    const allAircraftTypes: string[] = [];
    preferences.forEach((preference) => {
      const { aircraftType } = preference;
      allAircraftTypes.push(aircraftType || 'NA');
    });

    const mostFrequentAircraftType = [...allAircraftTypes]
      .sort(
        (a, b) =>
          allAircraftTypes.filter((v) => v === a).length -
          allAircraftTypes.filter((v) => v === b).length
      )
      .pop();

    const mostFrequentAircraftTypeCount = allAircraftTypes.filter(
      (v) => v === mostFrequentAircraftType
    ).length;

    return {
      type: mostFrequentAircraftType || '',
      count: mostFrequentAircraftTypeCount || 0,
    };
  };

  useEffect(() => {
    const { type, count } = getAircraftType(preferences || []);
    setAircraftType({
      type,
      count,
    });
    //console.log({ type });
    setShowSection(type !== 'NA');
  }, [preferences]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const itemToAnimate = itemRef.current;
    const itemTrigger = triggerRef.current;
    const windowWidth = window.innerWidth;

    if (windowWidth >= 993) {
      gsap.registerPlugin(ScrollTrigger);

      setTimeout(() => {
        gsap.to(itemToAnimate, {
          scrollTrigger: {
            trigger: itemTrigger,
            start: 'top center', // activa el trigger cuando la parte superior del trigger llega al fondo de la ventana del navegador
            end: 'top 50px', // desactiva el trigger cuando la parte inferior del trigger sale de la ventana del navegador
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
          x: '-40%',
          y: '-10%',
          opacity: 1,
          ease: 'easeOut',
          duration: 5,
        });

        if (itemTrigger !== null) {
          return () => ScrollTrigger.getById(itemTrigger?.id)?.kill(); // limpiar el trigger cuando se desmonta el componente
        }
      }, 1500);
    }
  }, []);

  return showSection ? (
    <>
      <section className="scene scene--color-3 scene--animated">
        <div ref={triggerRef} className="animation-offset offset-100"></div>
        <div className="container-fluid">
          <div className="plane-scene-section row">
            <div className="plane__col col-12">
              <div className="logo-320">
                <div className="lottie-player">
                  {aircraftType.type.toString() === 'A321' ? (
                    <Lottie options={animationThree} />
                  ) : (
                    <Lottie options={animationOne} />
                  )}
                </div>
              </div>
              <div ref={itemRef} className="animated-plane-version plane-color">
                <div className="lottie-player">
                  {aircraftType.type.toString() === 'A321' ? (
                    <Lottie options={animationTwo} />
                  ) : (
                    <Lottie options={animationFour} />
                  )}
                </div>
              </div>
            </div>
            <div className="plane__bottom  col-12">
              <div className="line-left text-center">
                <p className="h3 font-400">
                  Volando en la flota más joven de México
                </p>
              </div>
              <div className="line-right text-center">
                <p className="h2 white-text">
                  Este año volaste más veces{' '}
                  <span className="line-right__hide">{`en un Airbus ${aircraftType.type}`}</span>
                </p>
              </div>
              <div className="line-right__small text-center">
                <p className="h2 white-text">{`en un Airbus ${aircraftType.type}`}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default ScenePlane;
