import qs from 'qs';
import api from './index';
import { UserInformationResponse } from '../types/vivaaerobus';

export const getUserInfo = async (customer: string, identity: string) => {
  try {
    const response = await api.get<UserInformationResponse>(
      `/users/information/?${qs.stringify({ customer, identity })}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
