import React, { useEffect, useState, useRef } from 'react';
import { TravelRecord, newTravelRecord } from '../../../api/sheetBest';
import {
  Destination,
  Preference,
  UserInformation,
} from '../../../types/vivaaerobus';
import helpers from '../../../helpers';
import Swal from 'sweetalert2';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/loader.json';

interface Props {
  customer: Partial<UserInformation>;
}
import * as htmlToImage from 'html-to-image';

const SceneForm = ({ customer }: Props) => {
  const divRef = useRef(null);
  const footerRef = useRef(null);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isShareClicked, setIsShareClicked] = useState(false);
  const lottieInstanceRef = useRef<any>(null);
  const [badge, setBadge] = useState<any>(null);
  const [animationConfig, setAnimationConfig] = useState<any>({
    loop: false,
    autoplay: true,
    animationData: null,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    eventListeners: [
      {
        eventName: 'DOMLoaded',
        callback: (instance: any) => {
          lottieInstanceRef.current = instance;
        },
      },
    ],
  });
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [badgeType, setBadgeType] = useState({
    description: '',
    title: '',
    imagePath: '',
    label: '',
  });
  const [destination, setDestination] = useState<Destination | null>(null);
  const [travelRecord, setTravelRecord] = useState<TravelRecord>({
    customerNumber: '',
    external_id: '',
    travelPlans: '',
    travelPlanOther: '',
    destinations: '',
    travelAmount: '',
  });
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  // Detecta si el dispositivo es Android
  //const isAndroid = /Android/i.test(navigator.userAgent);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setTravelRecord({ ...travelRecord, [name]: value });
    if (name === 'travelPlans') {
      if (value === 'Otro') {
        setShowOtherField(true);
      } else {
        setShowOtherField(false);
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Registrar evento en dataLayer para Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ev_ux',
      category: 'ux',
      action: 'click_lp_desempacando2023',
      label: 'enviar_formulario_2024',
      detail: `${travelRecord.travelPlans}|${travelRecord.destinations}|${travelRecord.travelAmount}`, 
    });

    // Aquí van las verificaciones
    const { travelPlans, travelPlanOther, destinations, travelAmount } =
      travelRecord;

    if (!travelPlans || travelPlans === 'Seleccionar') {
      Swal.fire({
        title: 'Falta información',
        html: 'Por favor, selecciona tus <strong>planes de viaje</strong>.',
        icon: 'warning',
      });
      return;
    }

    if (showOtherField && !travelPlanOther) {
      Swal.fire({
        title: 'Falta información',
        html: 'Por favor, menciona tu <strong>otro plan de viaje</strong>.',
        icon: 'warning',
      });
      return;
    }

    if (!destinations) {
      Swal.fire({
        title: 'Falta información',
        html: 'Por favor, indica qué <strong>destinos nuevos</strong> quieres conocer.',
        icon: 'warning',
      });
      return;
    }

    if (!travelAmount || travelAmount === 'Seleccionar') {
      Swal.fire({
        title: 'Falta información',
        html: 'Por favor, selecciona <strong>cuántos viajes</strong> estimas hacer.',
        icon: 'warning',
      });
      return;
    }

    // Si todas las validaciones son correctas, se procede con el envío
    newTravelRecord(travelRecord)
      .then(() => {
        setShowThanksMessage(true);
        const userDetails = customer.userDetails || [];
        const customerNumber =
          userDetails.length > 0 ? userDetails[0].customerNumber : '';
        localStorage.setItem(
          `showThanksMessage-${customerNumber || ''}`,
          'true'
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMostRepeatedDestination = (preferences: Preference[]) => {
    if (!preferences) {
      return null;
    }

    const destinationCodes: Destination[] = [];
    preferences.forEach((preference) => {
      preference.destinations.forEach((destination) => {
        destinationCodes.push({ ...destination });
      });
    });

    if (!destinationCodes.length) {
      return null;
    }

    return destinationCodes[0];
  };

  const onShare = async () => {
    setIsLoading(true);
    setIsShareClicked(true);
  
    // Registrar evento en dataLayer para Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event": "ev_ux",
      "category": "ux",
      "action": "click_lp_desempacando2023",
      "label": "compartir"
    });
  
    // Asegúrate de que el divRef no sea null antes de continuar
    if (divRef.current) {
      const dataUrl = await htmlToImage
        .toJpeg(divRef.current, { quality: 1 })
        .then(function (dataUrl) {
          return dataUrl;
        });
  
        const resizeImage = async (originalDataUrl: string): Promise<string> => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = 720;
              canvas.height = 1280;
              const ctx = canvas.getContext('2d');
              
              if (!ctx) {
                return reject(new Error('No se pudo obtener el contexto 2D del canvas'));
              }
        
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              resolve(canvas.toDataURL());
            };
            img.onerror = () => {
              reject(new Error('No se pudo cargar la imagen'));
            };
            img.src = originalDataUrl;
          });
        };
        
        
  
      // Detecta si el dispositivo es móvil
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
  
        const fileName = `VivaEndOfYear.png`; // Asegúrate de que esta línea esté en el ámbito correcto

        const downloadImage = () => {
          const link = document.createElement('a');
          link.href = dataUrl; // Asegúrate de que 'dataUrl' también esté definido en este ámbito
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
  
      if (isMobile) {
        const resizedDataUrl = await resizeImage(dataUrl);
        const blob = await (await fetch(resizedDataUrl)).blob();
        const fileName = `VivaEndOfYear.jpeg`;
  
        // Mostrar SweetAlert con opciones
        Swal.fire({
          title: '¿Qué te gustaría hacer?',
          html: `
            <button id="shareButton" class="swal2-styled swal2-confirm">Compartir ahora</button>
            <button id="downloadButton" class="swal2-styled swal2-deny">Descargar</button>
            <article>
              <p>¿Problemas al compartir tu resumen? Intenta <strong>descargando</strong> la imagen y <strong>compártela</strong> en tus redes sociales con <strong class="green500-text ">#ViajerosReales</strong> y <strong class="green500-text ">#DejateVolar</strong></p>
            </article>
          `,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          didOpen: () => {
            const shareButton = document.getElementById('shareButton');
            const downloadButton = document.getElementById('downloadButton');
  
            if (shareButton) {
              shareButton.addEventListener('click', async () => {
                const filesArray = [
                  new File([blob], fileName, {
                    type:  blob.type,
                    lastModified: new Date().getTime(),
                  }),
                ];
                const shareData = {
                  files: filesArray,
                };
                return navigator.share(shareData);
              });
            }
            if(downloadButton){
              downloadButton.addEventListener('click', () => {
                downloadImage();
              });
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            console.log('Shared successfully');
          } else if (result.isDenied) {
            console.log('Image downloaded');
          }
        }).catch((error) => {
          console.error('Error sharing:', error);
        }).finally(() => {
          setIsLoading(false);
        });
      } else {
       
        // Computadoras de escritorio: Forzar descarga
        downloadImage();
        setIsLoading(false);
      }
    } else {
      console.error('El elemento de referencia es null.');
    }
  };
  
  // SHARE INSTAGRAM
  const handleOnShare = async () => onShare();

  useEffect(() => {
    const { userDetails } = customer;
    if (userDetails && userDetails.length > 0) {
      const { customerNumber, external_id } = userDetails[0];
      setTravelRecord({ ...travelRecord, customerNumber, external_id });
      const showThanksMessage = localStorage.getItem(
        `showThanksMessage-${customerNumber || ''}`
      );
      if (showThanksMessage && showThanksMessage === 'true') {
        setShowThanksMessage(true);
      }
    }
  }, [customer]);

  useEffect(() => {
    const badges = helpers.calculateBadges(customer);
    const mostRepeatedDestination = getMostRepeatedDestination(
      customer.preferences || []
    );
    setAnimationConfig({
      ...animationConfig,
      animationData: badges.animationData,
    });
    setBadge(badges);
    setBadgeType({
      description: badges.description,
      title: badges.title,
      imagePath: badges.imagePath,
      label: badges.label,
    });
    if (mostRepeatedDestination) {
      setDestination(mostRepeatedDestination);
    }
  }, [customer]);

  const hasFiredEvent = useRef(false);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsFooterVisible(entry.isIntersecting);
  
        if (entry.isIntersecting && !hasFiredEvent.current) {
          if (customer && customer.userDetails && customer.userDetails.length > 0) {
            window.dataLayer.push({
              event: "ev_ux",
              category: "lp_desempacando2023",
              action: "last_step",
              label: customer.userDetails[0].customerNumber
            });
  
            console.log(window.dataLayer.push);
            hasFiredEvent.current = true; // Marcar que el evento ya se ha disparado
          }
        }
      });
    }, { threshold: [0, 1.0] });
  
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }
  
    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <>
     <div className="arrow-downs" data-footer={isFooterVisible.toString()}>
        <span className="material-icons-outlined">arrow_downward</span>
      </div>
      <section ref={footerRef} className="scene scene--form scene--color-1">
        <div className="container-fluid">
          <div className="row">
            <div className="scene-end-form__col col-lg-6 col-12">
              
              {isLoading && (
                <div className="loader-share active">
                   <div className="loader-element__container">
                      <div className="lottie-player ">
                        <Lottie options={animationOne} />
                      </div>
                      <p>Estamos generando tu experiencia.</p>
                    </div>
                </div>
              )}

              <div ref={divRef} className={`scene-end__wrapp`}>
                <div className="scene-end__inner inner-result-award">
                  <div className="inner-result-award__col col-12">
                    <div className={`card--canvas-top card-result__top`}>
                      <div className="card-result__top-one">
                      
                        <h4 className="white-text">
                          Desempacando tu{' '}
                          <span className="greenLight-text ">2023</span>
                        </h4>
                      </div>
                      <div className="card-result__top-two">
                        <p
                          style={{ color: '#002612' }}
                        ><span dangerouslySetInnerHTML={{ __html: badgeType.label }}></span>,</p>
                        <p className="greenLight-text text-20">
                          <strong>
                            Soy Viajer@ {`${badgeType.description}`}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className={`card--canvas-bottom`}>
                      <div className="col-12 col-reset">
                        <div className="logo-viva">
                          <svg
                            width="190"
                            height="40"
                            viewBox="0 0 190 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.172 34.548C14.296 30.884 12.388 26.592 10.452 21.672C8.516 16.752 6.68 11.396 4.952 5.60001H13.648C14.012 7.03201 14.448 8.58001 14.948 10.248C15.448 11.916 15.964 13.6 16.5 15.308C17.032 17.016 17.564 18.676 18.1 20.292C18.636 21.908 19.164 23.372 19.684 24.692C20.16 23.372 20.676 21.904 21.228 20.288C21.784 18.672 22.328 17.012 22.856 15.304C23.388 13.604 23.904 11.92 24.4 10.244C24.896 8.56801 25.328 7.02001 25.7 5.59601H34.184C32.452 11.392 30.62 16.748 28.692 21.668C26.76 26.584 24.856 30.876 22.976 34.548H16.176"
                              fill="#34B233"
                            />
                            <path
                              d="M43.968 5.604H35.772V34.552H43.968V5.604Z"
                              fill="#34B233"
                            />
                            <path
                              d="M55.952 34.548C54.076 30.884 52.172 26.592 50.236 21.672C48.3 16.752 46.468 11.396 44.744 5.604H53.44C53.808 7.036 54.244 8.584 54.744 10.252C55.244 11.924 55.752 13.612 56.288 15.312C56.824 17.012 57.36 18.684 57.892 20.296C58.424 21.908 58.956 23.38 59.468 24.7C59.944 23.38 60.46 21.912 61.012 20.296C61.564 18.68 62.112 17.02 62.644 15.312C63.172 13.612 63.696 11.924 64.188 10.252C64.68 8.58 65.12 7.036 65.488 5.604H73.968C72.244 11.4 70.416 16.756 68.48 21.676C66.544 26.592 64.64 30.884 62.76 34.552H55.952"
                              fill="#34B233"
                            />
                            <path
                              d="M85.412 4.832C87.828 4.832 89.856 5.108 91.464 5.66C93.072 6.208 94.38 7 95.344 8.024C96.332 9.084 97.044 10.368 97.412 11.768C97.816 13.236 98.016 14.868 98.016 16.668V33.728C96.844 33.984 95.208 34.288 93.112 34.632C91.024 34.984 88.496 35.152 85.528 35.152C83.652 35.152 81.952 34.992 80.432 34.66C78.912 34.328 77.596 33.792 76.496 33.04C75.404 32.3 74.532 31.284 73.964 30.092C73.376 28.88 73.096 27.396 73.096 25.636C73.096 23.876 73.428 22.516 74.112 21.348C74.768 20.196 75.704 19.232 76.836 18.54C78.044 17.816 79.364 17.304 80.744 17.024C82.248 16.712 83.776 16.556 85.312 16.564C86.256 16.56 87.204 16.604 88.144 16.7C88.82 16.768 89.492 16.888 90.152 17.06V16.288C90.152 14.892 89.728 13.776 88.896 12.932C88.044 12.088 86.576 11.664 84.484 11.664C83.104 11.664 81.724 11.764 80.356 11.968C78.996 12.172 77.824 12.456 76.836 12.82L75.792 6.216C76.268 6.072 76.864 5.916 77.58 5.752C78.296 5.584 79.072 5.44 79.92 5.312C80.772 5.184 81.648 5.072 82.584 4.98C83.52 4.888 84.468 4.844 85.416 4.844M86.076 28.84C86.88 28.84 87.652 28.824 88.396 28.784C88.984 28.76 89.568 28.708 90.152 28.624V22.404C89.824 22.328 89.328 22.26 88.672 22.184C88.068 22.112 87.46 22.076 86.852 22.072C86.124 22.072 85.4 22.116 84.68 22.212C84.056 22.284 83.452 22.464 82.892 22.74C82.4 22.972 81.984 23.332 81.684 23.784C81.388 24.224 81.24 24.776 81.24 25.436C81.24 26.716 81.672 27.608 82.532 28.1C83.392 28.592 84.572 28.844 86.076 28.844V28.84Z"
                              fill="#34B233"
                            />
                            <path
                              d="M131.652 20.74C131.648 27.364 126.276 32.728 119.652 32.724C113.028 32.72 107.664 27.348 107.668 20.724C107.668 14.1 113.044 8.73599 119.668 8.73599C126.284 8.73599 131.656 14.116 131.656 20.736"
                              fill="#34B233"
                            />
                            <path
                              d="M125.668 15.684C125.66 17.276 124.364 18.556 122.776 18.552C121.184 18.544 119.9 17.252 119.904 15.664C119.904 14.072 121.2 12.788 122.788 12.788C123.556 12.788 124.292 13.092 124.832 13.636C125.372 14.18 125.676 14.92 125.668 15.688"
                              fill="white"
                            />
                            <path
                              d="M158.336 20.74C158.336 27.368 152.964 32.74 146.336 32.74C139.708 32.74 134.336 27.368 134.336 20.74C134.336 14.112 139.708 8.73999 146.336 8.73999C149.52 8.73999 152.572 10.004 154.824 12.252C157.076 14.504 158.34 17.556 158.336 20.74Z"
                              fill="white"
                            />
                            <path
                              d="M146.336 33.104C139.528 33.104 133.976 27.56 133.976 20.736C133.976 13.912 139.524 8.38 146.336 8.38C153.148 8.38 158.704 13.924 158.704 20.736C158.704 27.564 153.164 33.1 146.336 33.104ZM146.336 9.108C139.912 9.108 134.708 14.316 134.708 20.736C134.708 27.156 139.912 32.364 146.336 32.368C152.76 32.368 157.964 27.164 157.968 20.74C157.964 14.32 152.76 9.112 146.336 9.108Z"
                              fill="#A4A3A6"
                            />
                            <path
                              d="M152.36 15.684C152.356 17.276 151.06 18.568 149.468 18.564C147.876 18.564 146.584 17.272 146.58 15.676C146.576 14.08 147.868 12.788 149.46 12.784C150.232 12.78 150.972 13.084 151.516 13.628C152.06 14.172 152.364 14.912 152.36 15.684Z"
                              fill="#A4A3A6"
                            />
                            <path
                              d="M185.016 20.74C185.016 27.364 179.64 32.732 173.016 32.728C166.392 32.728 161.024 27.356 161.024 20.732C161.024 14.108 166.392 8.73999 173.016 8.73599C176.2 8.73599 179.252 9.99599 181.504 12.248C183.756 14.5 185.02 17.552 185.016 20.736"
                              fill="#D52B1E"
                            />
                            <path
                              d="M179.028 15.684C179.02 17.276 177.724 18.56 176.132 18.552C174.54 18.544 173.256 17.248 173.264 15.656C173.272 14.064 174.568 12.78 176.16 12.788C176.928 12.788 177.66 13.092 178.2 13.64C178.74 14.184 179.04 14.92 179.032 15.688"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="scene__badge-end">
                     
                        {badge && badge.image && <badge.image />} 
                      </div>
                      <article className="card-result__article">
                        <div className="row">
                          <div className="card-result__col col-6 text-center">
                            <p>Vuelos en 2023</p>
                            <p className="card-result__number">{`${
                              customer.segmentsFlown || 0
                            }`}</p>
                          </div>
                          <div className="card-result__col col-6 text-center">
                            <p>Destino favorito</p>
                            <p className="card-result__number">
                              {`${destination?.name || ''}`}
                            </p>
                          </div>
                          <div className="card-result__col col-6 text-center">
                            <p>Tiempo en el aire</p>
                            <p className="card-result__number">{`${customer.timeFlown?.toLocaleString()} minutos`}</p>
                          </div>
                          <div className="card-result__col col-6 text-center">
                            <p>Kilómetros recorridos</p>
                            <p className="card-result__number">
                              {customer.kilometersFlown
                                ? customer.kilometersFlown.toLocaleString()
                                : '0'}
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="scene-end__bottom">
                    {!isLoading && (
                      <button
                        type="button"
                        className="btn btn--type1 js-share-button"
                        onClick={handleOnShare}
                      >
                        Compartir
                      </button>
                    )}
                  </div>
                </div>
                <figure className="scene-end__back scene--share">
                  <img src={badgeType.imagePath} />
                </figure>
              </div>
            </div>

            <div className="form-main-center col-lg-6 col-12">
              {!showThanksMessage ? (
                <div className="form-center">
                  <article className="form__info text-center">
                    <figure className="form-center__icon">
                      <img
                        src="/assets/images/elements/avion.png"
                        alt="Avión"
                      />
                    </figure>
                    <h2 className="h3 white-text">Tus planes de viaje</h2>
                    <p className="counter">2024</p>
                  </article>
                  <div className="form__block">
                    <form method="post" onSubmit={handleSubmit}>
                      <div className="form-row row--wrapp">
                        <label className="form-label">
                          ¿Cuáles son tus planes de viaje para 2024?
                        </label>
                        <select
                          className="form-select"
                          aria-label="Planes"
                          name="travelPlans"
                          onChange={handleInputChange}
                        >
                          <option defaultValue="Seleccionar">
                            Seleccionar
                          </option>
                          <option value="Viajar por trabajo">
                            Viajar por trabajo
                          </option>
                          <option value="Viajar por ocio o visitas familiares">
                            Viajar por ocio o visitas familiares
                          </option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                      {showOtherField && (
                        <div className="form-row row--wrapp row--other">
                          <label className="form-label">Menciona el plan</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ingresa tu plan"
                            name="travelPlanOther"
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                      <div className="form-row row--wrapp">
                        <label className="form-label">
                          ¿Qué destinos nuevos quieres conocer?
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Cuéntanos"
                          name="destinations"
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <div className="form-row row--wrapp">
                        <label className="form-label">
                          ¿Cuántos viajes estimas hacer?
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="travelAmount"
                          onChange={handleInputChange}
                        >
                          <option defaultValue="Seleccionar">
                            Seleccionar
                          </option>
                          <option value="Entre 1 y 2 viajes">
                            Entre 1 y 2 viajes
                          </option>
                          <option value="Entre 2 y 4 viajes">
                            Entre 2 y 4 viajes
                          </option>
                          <option value="Más de 4 viajes">
                            Más de 4 viajes
                          </option>
                        </select>
                      </div>
                      <div className="form-row row--last">
                        <button type="submit" className="btn btn--type1">
                          Enviar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="form-main__thanks col-12">
                  <article className="form__info text-center">
                    <h2 className="white-text">
                      Gracias por compartirnos tus planes de viaje para el
                    </h2>
                    <p className="counter">2024</p>
                  </article>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneForm;
