import React, { useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/03.json';
import { UserInformation } from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
interface Props {
  customer: Partial<UserInformation>;
}

const SceneKMTime = ({ customer: { kilometersFlown, timeFlown } }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null), // Tercera referencia
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    itemRefs.forEach((ref, index) => {
      let animationConfig;
      if (index === 2) {
        // Configuración específica para el tercer elemento
        animationConfig = {
          y: 0,
          scale: 1,
          opacity: 1,
          duration: 5,
        };
      } else {
        animationConfig = {
          y: index === 0 ? 0 : 0, // Posición para los primeros dos elementos
          opacity: 1,
          ease: 'easeOut',
          duration: 3,
        };
      }

      gsap.to(ref.current, {
        ...animationConfig,
        scrollTrigger: {
          trigger: triggerRef.current,
          start: 'top center',
          end: 'top 50px',
          scrub: true,
          pinSpacing: false,
          markers: false,
        },
      });
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <section className="scene scene--color-3 scene--animated">
        <div ref={triggerRef} className="animation-offset"></div>
        <div className="container km-container">
          <div className="row">
            <div ref={itemRefs[0]} className="km-animated-1 km-container__col">
              <div className="km-container-card card--left">
                <article className="text-center">
                  <div className="card-top">
                    <p className="h1 greenLight-text">
                      {kilometersFlown ? kilometersFlown.toLocaleString() : '0'}
                    </p>
                    <p className="h1 greenLight-text">kilómetros</p>
                  </div>
                  <div className="card-bottom">
                    <p className="h4 green-text">
                      Es la distancia total que recorriste este año.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div
              ref={itemRefs[2]}
              className="km-animated-2 km-container__col col--lottie"
            >
              <div className="lottie-player">
                <Lottie options={defaultOptions} />
              </div>
            </div>
            <div ref={itemRefs[1]} className="km-animated-3 km-container__col">
              <div className="km-container-card card--right">
                <article className="text-center">
                  <div className="card-top">
                    <p className="h4 green-text">¡Y pasaste</p>
                    <p className="h1 greenLight-text">{`${(
                      timeFlown || 0
                    ).toLocaleString()}`}</p>
                    <p className="h1 greenLight-text">minutos en el aire!</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneKMTime;
