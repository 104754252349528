import React, {useRef, useEffect} from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/05.json';
import animationDataTwo from '../../../lotties/06.json';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SceneSubEnd = () => {
  const defaultOptionsOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptionsTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const itemToAnimate = itemRef.current;
    const itemTrigger = triggerRef.current;
    const windowWidth = window.innerWidth;

    if (windowWidth >= 993) {
      gsap.registerPlugin(ScrollTrigger);

     setTimeout(() => {
      gsap.to(
        itemToAnimate,
        {
          scrollTrigger: {
            trigger: itemTrigger,
            start: "top center", // activa el trigger cuando la parte superior del trigger llega al fondo de la ventana del navegador
            end: 'top 50px', // desactiva el trigger cuando la parte inferior del trigger sale de la ventana del navegador
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
          x: 0,
          opacity: 1,
          ease: "easeOut",
          duration: 3,
          
        }
      );
  
      if(itemTrigger !== null){
        return () => ScrollTrigger.getById(itemTrigger?.id)?.kill(); // limpiar el trigger cuando se desmonta el componente
      }
     }, 1500);
    }

   
    
  }, []);

  return (
    <>
      <section className="scene scene--color-6 scene--animated">
        <div ref={triggerRef}  className="animation-offset"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div ref={itemRef} className="plane-element plane--subend animated-plane">
                <article>
                  <p className="h2 green-text">
                    Descubre qué tipo de viajero eres…
                  </p>
                </article>
                <div className="lottie-player player--desktop">
                  <Lottie options={defaultOptionsOne} />
                </div>
                <div className="lottie-player player--mobile">
                  <Lottie options={defaultOptionsTwo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneSubEnd;
