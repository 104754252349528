import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://sheet.best/api/sheets/59d5236e-838f-47f0-9ac7-efa982cace12',
  headers: {
    'x-api-key':
      '%WK%5#t65kx1j6lxkg%@kye$QaF!I-2E0NuXDW-xfCSbUr#Wvts3-Ry286L7b6et',
  },
});

export interface TravelRecord {
  customerNumber: string;
  external_id: string;
  travelPlans: string;
  travelPlanOther: string;
  destinations: string;
  travelAmount: string;
}

export const newTravelRecord = async (data: TravelRecord) => {
  try {
    const response = await instance.post<TravelRecord[]>('', data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
