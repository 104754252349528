import React from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/23_MundoVerde.json';
import animationDataTwo from '../../../lotties/02.json';
import animationDataThree from '../../../lotties/01_Hojas.json';
import { UserInformation } from '../../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const SceneCarbono = ({ customer: { carbonFootprints } }: Props) => {
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationThree = {
    loop: true,
    autoplay: true,
    animationData: animationDataThree,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return carbonFootprints ? (
    <>
      <section className="scene scene--color-4">
        <div className="container">
          <div className="carbono-center">
            <div className="green-badge-left">
              <p>¡Cuidando el planeta!</p>
            </div>
            <div className="happy-face">
              <div className="lottie-player">
                <Lottie options={animationTwo} />
              </div>
            </div>
            <div className="green-world">
              <div className="lottie-player">
                <Lottie options={animationOne} />
              </div>
            </div>
            <figure className="leaft">
              <div className="lottie-player">
                <Lottie options={animationThree} />
              </div>
            </figure>
            <div className="orange-card">
              <p className="h3">
                  Compensaste tu huella de carbono
                  <br />
                  en <span className="counter color--white">{carbonFootprints}</span>{' '}
                  {carbonFootprints === 1 ? 'vuelo' : 'vuelos'}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default SceneCarbono;
