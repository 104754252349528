import React from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/27_Celular.json';
import animationDataTwo from '../../../lotties/51_Maleta.json';

const SceneFinish = () => {
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <section className="scene scene--color-1">
        <div className="container">
          <div className="row">
            <div className="form-main-center form--single col-md-6 col-12 mx-auto">
              <div className="phone-image mode-image">
                  <div className="lottie-player">
                    <Lottie options={animationOne} />
                  </div>
                </div>
                <div className="maleta">
                  <div className="lottie-player">
                    <Lottie options={animationTwo} />
                  </div>
                </div>
                <figure className="plane-small">
                  <img src="/assets/images/elements/avion.png" alt="Avión" />
                </figure>
                <figure className="bag-small">
                  <img
                    src="/assets/images/elements/maleta-amarilla.png"
                    alt="Maleta Amarilla"
                  />
              </figure>
              <div className="form-main__thanks col-12">
                <article className="form__info text-center">
                  <h2 className="greenLight-text">
                  Desempacando tu 2023
                  </h2>
                  <p className="text-white h3 font-400 mb-5">¿Quieres conocer tus destinos favoritos, tus preferencias de viaje y tu estilo de viajero? Sigue volando con Viva ¡y podrás explorar todo eso y mucho más!</p>
                  <p className='text-white'>Nosotros te haremos saber cuando esté listo tu 2024 para ser desempacado.</p>
                </article>
              </div>
            </div>
          </div>
        </div>
       
      </section>
    </>
  );
};

export default SceneFinish;
