import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/27_Celular.json';
import animationDataTwo from '../../../lotties/20_Boleto_2.json';
import animationDataThree from '../../../lotties/09_Mochila.json';
import animationDataFour from '../../../lotties/51_Maleta.json';
import animationDataFive from '../../../lotties/48_Asientos.json';
import { Preference, UserInformation } from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
interface Props {
  customer: Partial<UserInformation>;
}

const SceneMode = ({ customer: { preferences } }: Props) => {
  const [modality, setModality] = useState(<></>);
  //console.log({ setModality, preferences });
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationThree = {
    loop: true,
    autoplay: true,
    animationData: animationDataThree,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationFour = {
    loop: true,
    autoplay: true,
    animationData: animationDataFour,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationFive = {
    loop: true,
    autoplay: true,
    animationData: animationDataFive,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getModalityType = (preferences: Preference[]) => {
    const allBundleTypes: string[] = [];
    preferences.forEach((preference) => {
      const { bundle } = preference;
      bundle.forEach((b) => {
        allBundleTypes.push(b.name || 'NA');
      });
    });

    const mostFrequentBundleType = [...allBundleTypes]
      .sort(
        (a, b) =>
          allBundleTypes.filter((v) => v === a).length -
          allBundleTypes.filter((v) => v === b).length
      )
      .pop();

    switch (mostFrequentBundleType?.toLocaleLowerCase()) {
      case 'smart':
        return (
          <>
            <div className="mode-seat">
              <div className="lottie-player">
                <Lottie options={animationFive} />
              </div>
            </div>
            <div className="mode-bags" data-info="smart">
              <div className="mode-bag__small">
                <div className="lottie-player">
                  <Lottie options={animationThree} />
                </div>
              </div>
              <div className="mode-bag__middle">
                <div className="lottie-player">
                  <Lottie options={animationFour} />
                </div>
              </div>
              <figure className="mode-maleta__yellow">
                <img
                  src="/assets/images/elements/maleta-amarilla.png"
                  alt="Maleta"
                />
              </figure>
            </div>
            <p className="h3 font-400">Listo y equipado para todo.</p>
            <p className="green400-text h2">Volaste en modalidad Smart.</p>
          </>
        );
      case 'extra':
        return (
          <>
            <div className="mode-bags" data-info="extra">
              <div className="mode-bag__small">
                <div className="lottie-player">
                  <Lottie options={animationThree} />
                </div>
              </div>
              <div className="mode-bag__middle">
                <div className="lottie-player">
                  <Lottie options={animationFour} />
                </div>
              </div>
              <figure className="mode-maleta__yellow">
                <img
                  src="/assets/images/elements/maleta-amarilla.png"
                  alt="Maleta"
                />
              </figure>
            </div>
            <p className="h3 font-400">
              Viajando a tu medida (y con un poquito extra).
            </p>
            <p className="green400-text h2">Volaste en modalidad Extra.</p>
          </>
        );
      case 'light':
        return (
          <>
            <div className="mode-bags" data-info="light">
              <div className="mode-bag__small">
                <div className="lottie-player">
                  <Lottie options={animationThree} />
                </div>
              </div>
              <figure className="mode-maleta__yellow">
                <img
                  src="/assets/images/elements/maleta-amarilla.png"
                  alt="Maleta"
                />
              </figure>
            </div>
            <p className="h3 font-400">Para ti, volar ligero es la clave.</p>
            <p className="green400-text h2">Volaste en modalidad Light.</p>
          </>
        );
      case 'zero':
        return (
          <>
            <div className="mode-bags" data-info="zero">
              <div className="mode-bag__small">
                <div className="lottie-player">
                  <Lottie options={animationThree} />
                </div>
              </div>
            </div>
            <p className="h3 font-400">Viajando con lo justo y necesario</p>
            <p className="green400-text h2">Volaste en modalidad Zero.</p>
          </>
        );
      default:
        return (
          <>
            <div className="mode-seat">
              <div className="lottie-player">
                <Lottie options={animationFive} />
              </div>
            </div>
            <div className="mode-bags" data-info="light">
              <div className="mode-bag__small">
                <div className="lottie-player">
                  <Lottie options={animationThree} />
                </div>
              </div>
              <div className="mode-bag__middle">
                <div className="lottie-player">
                  <Lottie options={animationFour} />
                </div>
              </div>
              <figure className="mode-maleta__yellow">
                <img
                  src="/assets/images/elements/maleta-amarilla.png"
                  alt="Maleta"
                />
              </figure>
            </div>
            <p className="h3 font-400">
              ¡Tú te adaptas a la modalidad que sea!
            </p>
            <p className="green400-text h2">
              ¿Zero, Light, Extra, Smart? ¿Por qué no mejor un poquito de todo?
            </p>
          </>
        );
    }
  };

  useEffect(() => {
    const modality = getModalityType(preferences || []);
    setModality(modality);
  }, [preferences]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null), // Tercera referencia
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      itemRefs.forEach((ref, index) => {
        let animationConfig;
        if (index === 2) {
          // Configuración específica para el tercer elemento
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 5,
          };
        } else {
          animationConfig = {
            y: 0,
            scale: index === 0 ? 1 : 1, // Posición para los primeros dos elementos
            opacity: 1,
            ease: 'easeOut',
            duration: 3,
          };
        }
  
        gsap.to(ref.current, {
          ...animationConfig,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top center',
            end: 'top 50px',
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
        });
      });
  
      return () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    },1500);
  }, []);

  return (
    <>
      <section className="scene scene--color-5 scene--animated">
        <div ref={triggerRef} className="animation-offset offset-0"></div>
        <div className="container">
          <div className="row">
            <div className="mode-block col-12">
              <div
                ref={itemRefs[2]}
                className="mode-element-3 badge-simple badge--mode text-center"
              >
                <div
                  ref={itemRefs[0]}
                  className="mode-element-1 phone-image mode-image"
                >
                  <div className="lottie-player">
                    <Lottie options={animationOne} />
                  </div>
                </div>
                <div
                  ref={itemRefs[1]}
                  className="mode-element-2 ticket-image mode-image"
                >
                  <div className="lottie-player">
                    <Lottie options={animationTwo} />
                  </div>
                </div>
                {modality}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneMode;
