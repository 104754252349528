import React, { useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import animationDataOne from '../../../lotties/48_Asientos.json';
import helpers from '../../../helpers';
import { UserInformation } from '../../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const ScenePlace = ({ customer }: Props) => {
  const [hasTopSeatBrand, setHasTopSeatBrand] = React.useState<{
    title: string;
    label: string;
  }>({
    title: '',
    label: '',
  });
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);


  const findHasTopSeatBrand = (
    seatBrands: {
      brand: string;
      count: number;
    }[]
  ) => {
    const defaultResponse = { title: '', label: '' };
    const VIP = ['VIP', 'Preferente'];
    const extra = ['Extra'];

    if (seatBrands.length === 0) return defaultResponse;

    const hasVIP = seatBrands.some((seatBrand) =>
      VIP.includes(seatBrand.brand)
    );
    const hasExtra = seatBrands.some((seatBrand) =>
      extra.includes(seatBrand.brand)
    );

    if (hasVIP) {
      return {
        title: 'Lo top de lo top',
        label: 'Volaste en asientos VIP o Preferente',
      };
    }
    if (hasExtra) {
      return {
        title: 'Viajando con más espacio.',
        label: 'Volaste en asientos Extra',
      };
    }

    return defaultResponse;
  };

  useEffect(() => {
    const itemToAnimate = itemRef.current;
    const itemTrigger = triggerRef.current;
    
    //console.log(itemToAnimate)

    if (hasTopSeatBrand.title !== '' && window.innerWidth >= 769) {
      setTimeout(() => {
        //console.log('INIT')
      gsap.registerPlugin(ScrollTrigger);

      gsap.to(itemToAnimate, {
        scrollTrigger: {
          trigger: itemTrigger,
          start: 'top center', // activa el trigger cuando la parte superior del trigger llega al fondo de la ventana del navegador
          end: 'top 50px', // desactiva el trigger cuando la parte inferior del trigger sale de la ventana del navegador
          scrub: true,
          pinSpacing: false,
          markers: false,
        },
        x: 0,
        opacity: 1,
        scale: 1,
        ease: 'easeOut',
        duration: 3,
      });

      if (itemTrigger !== null) {
        return () => ScrollTrigger.getById(itemTrigger?.id)?.kill(); // limpiar el trigger cuando se desmonta el componente
      }
      }, 1500);
    }
  }, [hasTopSeatBrand.title]);

  useEffect(() => {
    if (customer.benefitsUsed) {
      const seatBrands = helpers.getSeatBrands(customer.preferences || []);
      setHasTopSeatBrand(findHasTopSeatBrand(seatBrands));
    }
  }, [customer]);

  return hasTopSeatBrand.title !== '' ? (
    <>
      <section className="scene scene--color-1 scene--animated">
        <div ref={triggerRef} className="animation-offset offset-0"></div>
        <div className="container">
          <div className="row">
            <div className="place-container">
              <figure
                ref={itemRef}
                className="animated-chair chair-place-premium"
              >
                <img
                  src="/assets/images/elements/premium-chair.png"
                  alt="Lugar de avión"
                />
              </figure>
              <div className="badge-chair">
                <div className="lottie-player">
                  <Lottie options={animationOne} />
                </div>
              </div>
              <div className="badge-simple badge--mode text-center">
                <p className="h3 font-400">{`${hasTopSeatBrand.title}`}</p>
                <p className="green400-text h2">{`${hasTopSeatBrand.label}`}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default ScenePlace;
