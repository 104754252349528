import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/21_Desierto.json';
import animationDataTwo from '../../../lotties/06_Paisaje_2.json';
import animationDataThree from '../../../lotties/01_VivaFan.json';
import {
  Membership,
  Preference,
  UserInformation,
} from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
interface Props {
  customer: Partial<UserInformation>;
}

const SceneSale = ({ customer: { preferences, membership } }: Props) => {
  const [showSection, setShowSection] = useState(true);
  const [totalPromotionalBookings, setTotalPromotionalBookings] = useState(0);
  const [totalVivaFanMembership, setTotalVivaFanMembership] = useState(0);
  const [totalCobrandMembership, setTotalCobrandMembership] = useState(0);
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationThree = {
    loop: true,
    autoplay: true,
    animationData: animationDataThree,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getTotalPromotionalBookings = (preferences: Preference[]) => {
    if (!preferences) return 0;

    const promotionalBookings = preferences
      .map((preference) => {
        return preference.promotionalBookings;
      })
      .reduce((a, b) => a + b, 0);

    return promotionalBookings;
  };

  const getTotalVivaFanMembership = (membership: Membership[]) => {
    const totalVivaFanMembership = membership.find(
      (membership) => membership.type === 'VivaFan'
    );

    if (totalVivaFanMembership) return totalVivaFanMembership.number || 0;

    return 0;
  };

  const getTotalCobrandMembership = (membership: Membership[]) => {
    const totalCobrandMembership = membership.find(
      (membership) => membership.type === 'Cobrand'
    );

    if (totalCobrandMembership) return totalCobrandMembership.number || 0;

    return 0;
  };

  useEffect(() => {
    const totalPromotionalBookings = getTotalPromotionalBookings(
      preferences || []
    );
    setTotalPromotionalBookings(totalPromotionalBookings);

    const totalVivaFanMembership = getTotalVivaFanMembership(membership || []);
    setTotalVivaFanMembership(totalVivaFanMembership);

    const totalCobrandMembership = getTotalCobrandMembership(membership || []);
    setTotalCobrandMembership(totalCobrandMembership);

    setShowSection(
      !!totalPromotionalBookings ||
        !!totalVivaFanMembership ||
        !!totalCobrandMembership
    );
  }, []);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    itemRefs.forEach((ref, index) => {
      const xPos = index === 0 ? 0 : 0; // ejemplo de diferenciación de posición basado en el índice
      const yPos = index === 0 ? 0 : 0; // ejemplo de diferenciación de posición basado en el índice

      gsap.to(ref.current, {
        scrollTrigger: {
          trigger: triggerRef.current,
          start: 'top center',
          end: 'top 50px',
          scrub: true,
          pinSpacing: false,
          markers: false,
        },
        x: xPos,
        y: yPos,
        scale: 1,
        opacity: 1,
        ease: 'easeOut',
        duration: 3,
      });
    });

    return () => {
      // Limpiar todos los triggers creados
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return showSection ? (
    <>
      <section className="scene scene--color-1 scene--animated">
        <div ref={triggerRef} className="animation-offset offset-0"></div>
        <div className="container">
          <div className="row">
            <div className=" sale-top">
              <div ref={itemRefs[0]} className="badge250 animated-badge250">
                <div className="lottie-player">
                  <Lottie options={animationOne} />
                </div>
              </div>
              <div ref={itemRefs[1]} className="sale-col animated-sale-top">
                {totalPromotionalBookings > 0 && (
                  <div className="sale-badge">
                    <div className="row">
                      <div className="sale-badge__left sale-badge__col col-8">
                        <article className="badge-white text-center">
                          <div>
                            <p>La vida es mejor ahorraaaando…</p>
                            <div className="sale-badge__inner">
                              <p className="h4 green-text">
                                Este año aprovechaste
                              </p>
                              <p className="h2 greenLight-text">
                                {`${totalPromotionalBookings} ${
                                  totalPromotionalBookings > 1
                                    ? 'promociones'
                                    : 'promoción'
                                }`}
                              </p>
                              <p className="h4 green-text">
                                y volaste más barato.
                              </p>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="sale-badge__right sale-badge__col col-4">
                        <div className="badge-logo-right badge--color1">
                          <figure>
                            <img
                              src="/assets/images/elements/discount.png"
                              alt="Descuento"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {totalVivaFanMembership > 0 && (
                  <div
                  className='sale-badge'
                  data-move={totalPromotionalBookings > 0 && totalVivaFanMembership > 0 && totalCobrandMembership > 0}

                  >
                  <div className="row">
                      <div className="sale-badge__left sale-badge__col col-8">
                        <article className="badge-white text-center">
                          <div>
                            <p>¡Ser Viva Fan es tarifas bajas y equipaje extra!</p>
                            <div className="sale-badge__inner">
                              <p className="h4 bluedark-text">Este año volaste</p>
                              <p className="h2 blue-text">
                                {`${totalVivaFanMembership.toLocaleString()} ${
                                  totalVivaFanMembership > 1 ? 'veces' : 'vez'
                                }`}
                              </p>
                              <p className="h4 bluedark-text">con tu membresía.</p>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="sale-badge__right sale-badge__col col-4">
                        <div className="badge-logo-right badge--color2">
                          <div className="viva-lottie">
                            <div className="lottie-player">
                              <Lottie options={animationThree} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {totalCobrandMembership > 0 && (
                  <div
                    className='sale-badge'
                    data-move={totalPromotionalBookings > 0 && totalVivaFanMembership > 0 && totalCobrandMembership > 0}

                  >
                    <div className="row">
                      <div className="sale-badge__left sale-badge__col col-8">
                        <article className="badge-white text-center">
                          <div>
                            <p>¿La mejor tarjeta? ¡Claro que sí!</p>
                            <div className="sale-badge__inner">
                              <p className="h4">Este año disfrutaste</p>
                              <p className="h2 red-text">
                                {`${totalCobrandMembership.toLocaleString()} `}
                                {totalCobrandMembership === 1 ? 'vez' : 'veces'}
                              </p>
                              <p className="h4">
                                de los beneficios de tu Tarjeta de Crédito HSBC
                                Viva.
                              </p>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="sale-badge__right sale-badge__col col-4">
                        <div className="badge-logo-right badge--color3">
                          <figure>
                            <img src="/assets/images/logo/hsbc.svg" alt="HSBC" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


              </div>
              <div ref={itemRefs[2]} className="badge250 animated-badge250">
                <div className="lottie-player">
                  <Lottie options={animationTwo} />
                </div>
              </div>
            </div>
            <div ref={itemRefs[3]} className="animated-sale-bottom sale-bottom">
              {totalPromotionalBookings > 0 && totalVivaFanMembership > 0 && totalCobrandMembership > 0 && (
                    
                    <>
                      {totalVivaFanMembership > 0 && (
                        <div
                        className='sale-badge'
                        >
                        <div className="row">
                            <div className="sale-badge__left sale-badge__col col-8">
                              <article className="badge-white text-center">
                                <div>
                                  <p>¡Ser Viva Fan es tarifas bajas y equipaje extra!</p>
                                  <div className="sale-badge__inner">
                                    <p className="h4 bluedark-text">Este año volaste</p>
                                    <p className="h2 blue-text">
                                      {`${totalVivaFanMembership.toLocaleString()} ${
                                        totalVivaFanMembership > 1 ? 'veces' : 'vez'
                                      }`}
                                    </p>
                                    <p className="h4 bluedark-text">con tu membresía.</p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div className="sale-badge__right sale-badge__col col-4">
                              <div className="badge-logo-right badge--color2">
                                <div className="viva-lottie">
                                  <div className="lottie-player">
                                    <Lottie options={animationThree} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {totalCobrandMembership > 0 && (
                        <div
                          className='sale-badge'
                        >
                          <div className="row">
                            <div className="sale-badge__left sale-badge__col col-8">
                              <article className="badge-white text-center">
                                <div>
                                  <p>¿La mejor tarjeta? ¡Claro que sí!</p>
                                  <div className="sale-badge__inner">
                                    <p className="h4">Este año disfrutaste</p>
                                    <p className="h2 red-text">
                                      {`${totalCobrandMembership.toLocaleString()} `}
                                      {totalCobrandMembership === 1 ? 'vez' : 'veces'}
                                    </p>
                                    <p className="h4">
                                      de los beneficios de tu Tarjeta de Crédito HSBC
                                      Viva.
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div className="sale-badge__right sale-badge__col col-4">
                              <div className="badge-logo-right badge--color3">
                                <figure>
                                  <img src="/assets/images/logo/hsbc.svg" alt="HSBC" />
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  
                )}
              </div>
              
               
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default SceneSale;
