import React, { useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/04.json';
import animationDataTwo from '../../../lotties/29_Avion.json';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const SceneSmile = () => {
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      itemRefs.forEach((ref, index) => {
        let animationConfig;
        if (index === 1) {
          // Configuración específica para el tercer elemento
          animationConfig = {
            y: 0,
            opacity: 1,
            duration: 5,
          };
        } else if (index === 2) {
          // Configuración específica para el tercer elemento
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 10,
          };
        } else if (index === 3) {
          // Configuración específica para el tercer elemento
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 3,
          };
        } else {
          animationConfig = {
            y: 0, // Posición para los primeros dos elementos
            opacity: 1,
            scale: 1,
            ease: 'easeOut',
            duration: 3,
          };
        }
  
        gsap.to(ref.current, {
          ...animationConfig,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top center',
            end: 'top 50px',
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
        });
      });
  
      return () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      }; 
    },1500);
   
  }, []);

  return (
    <>
      <section className="scene scene--color-4 scene--animated">
        <div ref={triggerRef} className="animation-offset"></div>
        <div className="container">
          <div className="row">
            <div className="smile-content__col col-12">
              <div className="smile-content">
                <figure
                  ref={itemRefs[0]}
                  className="smile-element smile--1 smile-logo"
                >
                  <img
                    src="/assets/images/logo/smile-fly.png"
                    alt="Smile Fly"
                  />
                </figure>
                <div
                  ref={itemRefs[1]}
                  className="smile-element smile--2 smile-phone"
                >
                  <div className="lottie-player">
                    <Lottie options={animationOne} />
                  </div>
                </div>
                <div
                  ref={itemRefs[2]}
                  className="smile-element smile--3 yellow-plane"
                >
                  <div className="lottie-player">
                    <Lottie options={animationTwo} />
                  </div>
                </div>
                <figure
                  ref={itemRefs[3]}
                  className="smile-element smile--4 stars"
                >
                  <img src="/assets/images/elements/stars.svg" alt="Stars" />
                </figure>
                <div className="badge-green text-center">
                  <p className="h3 font-400 white-text">
                    Sonreír te abrió muchas puertas este año.
                  </p>
                  <p className="h2 greenLight-text">
                    Usaste la tecnología de Smile & Fly para abordar y
                    documentar
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneSmile;
