import React, {useState, useEffect} from 'react';
import { UserDetail, UserInformation } from '../../../types/vivaaerobus';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/00_Malet_Intro_v2.json';
import animationDataTwo from '../../../lotties/00_Malet_Intro_v2_mobile.json';


interface Props {
  customer: Partial<UserInformation>;
}

const SceneInit = ({ customer: { userDetails } }: Props) => {
  const [showTitle, setShowTitle] = useState(false);
  const [useVideo, setUseVideo] = useState(false);
  const userDetail: Partial<UserDetail> =
    userDetails && userDetails.length ? userDetails[0] : {};


  const animationOne = {
    loop: false,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationTwo = {
    loop: false,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIpad = /iPad/.test(userAgent);
    const iosVersionMatch = /OS (\d+)_/.exec(userAgent);

    if (isIpad && iosVersionMatch) {
      const iosVersion = parseInt(iosVersionMatch[1], 10);
      if (iosVersion === 11 || iosVersion === 12) {
        //console.log('iOS version', iosVersion);
        setUseVideo(true);
      }
    }
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTitle(true);
    }, 1000);

    // Limpieza del timer cuando el componente se desmonte o antes de que se ejecute el próximo efecto
    return () => clearTimeout(timer);
  }, []);

  const divClass = useVideo ? "init-image lottie--video" : "init-image";

  return (
    <>
      <section className="scene scene--color-4 scene--init">
        <div className="container">
          <div className={divClass}>
            <h1 className={showTitle ? 'active' : ''}>
              {`${userDetail.firstName || ''}`}, <br />
              desempacando tu <span>2023</span>
            </h1>
            <div className="init-lottie">
              {useVideo ? (
                  <>
                    <video
                        playsInline
                        autoPlay={true}
                        controls={false}
                        muted={true}
                        width={400}
                        className="init-lottie__desktop"
                      >
                        <source src="/assets/video/00_Malet_Intro_v2.mp4" type="video/mp4" />
                      </video>
                     
                  </>
                  ) : (
                    <div className="lottie-player init-lottie__desktop">
                      <Lottie options={animationOne} />
                    </div>
                  )}
                 
              <div className="lottie-player init-lottie__mobile">
                <Lottie options={animationTwo} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneInit;
