import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/45_Cono.json';
import {
  BenefitsUsed,
  Membership,
  UserInformation,
} from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
interface Props {
  customer: Partial<UserInformation>;
}

const SceneDoters = ({ customer: { benefitsUsed, membership } }: Props) => {
  const [pointsAcumulation, setPointsAcumulation] = useState(0);
  const [pointsUsed, setPointsUsed] = useState(0);
  const [dotersMembership, setDotersMembership] = useState<Partial<Membership>>(
    {}
  );
  const [dotersLevel, setDotersLevel] = useState({
    style: '',
    image: '',
  });
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const totalDotersPointsAcumulation = (benefitsUsed: BenefitsUsed[]) => {
    if (!benefitsUsed)
      return {
        dotersPointsAcumulation: 0,
        dotersPointsUsed: 0,
      };

    const dotersPointsAcumulation = benefitsUsed
      .map((benefit) => {
        const doters = benefit.doters || [];
        const pointsAcumulation = doters
          .map((doter) => {
            return doter.pointsAcumulation;
          })
          .reduce((a, b) => a + b, 0);
        return pointsAcumulation;
      })
      .reduce((a, b) => a + b, 0);

    const dotersPointsUsed = benefitsUsed
      .map((benefit) => {
        const doters = benefit.doters || [];
        const pointsUsed = doters
          .map((doter) => {
            return doter.pointsUsed;
          })
          .reduce((a, b) => a + b, 0);
        return pointsUsed;
      })
      .reduce((a, b) => a + b, 0);

    return { dotersPointsAcumulation, dotersPointsUsed };
  };

  useEffect(() => {
    const { dotersPointsAcumulation, dotersPointsUsed } =
      totalDotersPointsAcumulation(benefitsUsed || []);
    //console.log({ dotersPointsAcumulation, dotersPointsUsed, pointsUsed });
    setPointsAcumulation(dotersPointsAcumulation);
    setPointsUsed(dotersPointsUsed);
    const dotersMembership = membership?.find(
      (member) => member.type === 'Doters'
    );
    if (dotersMembership && dotersMembership.level) {
      switch (dotersMembership.level) {
        case 'Plata':
          setDotersLevel({
            style: 'tier--1',
            image: '_silver',
          });
          break;
        case 'Oro':
          setDotersLevel({
            style: 'tier--2',
            image: '_gold',
          });
          break;
      }
    }
    setDotersMembership(dotersMembership || {});
  }, [benefitsUsed]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      itemRefs.forEach((ref, index) => {
        let animationConfig;
        if (index === 1) {
          // Configuración específica para el tercer elemento
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 10,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 2) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 2,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 3) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 5,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 4) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 3,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 5) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 2,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 6) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 5,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 7) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 3,
            ease: 'easeOut',
            delay: 4,
          };
        } else if (index === 8) {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 3,
            ease: 'easeOut',
            delay: 4,
          };
        } else {
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 3,
            ease: 'easeOut',
            delay: 4,
          };
        }
  
        gsap.to(ref.current, {
          ...animationConfig,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top center',
            end: 'top 50px',
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
        });
      });
  
      return () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    },1500);
  }, []);

  return (
    <>
      <section
        className={`scene scene--color-7 scene--animated scene-animated--doters ${dotersLevel.style}`}
      >
        <div ref={triggerRef} className="animation-offset  offset-0"></div>
        <div className="container">
          <div className="row">
            <div className="doters-center">
              <div
                ref={itemRefs[0]}
                className={`animated-badge badge--1 badge-doter-points doter--use ${
                  pointsUsed > 0 ? '' : 'hidden'
                }`}
              >
                <p className="h1 doters-text">{`${pointsUsed.toLocaleString()}`}</p>
              </div>
              <div
                ref={itemRefs[1]}
                className="animated-badge badge--2 badge-doter-points doter--win"
              >
                <p className="h1 doters-text">{`${pointsAcumulation.toLocaleString()}`}</p>
              </div>
              <figure
                ref={itemRefs[2]}
                className={`animated-badge badge--3 badge-circle circle--1`}
              >
                <img
                      src="/assets/images/elements/dot-blue.png"
                      alt="Doters"
                    />
              </figure>
              <figure
                ref={itemRefs[3]}
                className="animated-badge badge--4 badge-circle circle--2"
              >
                 <img
                    src="/assets/images/elements/dot-pink.png"
                    alt="Doters"
                  />
              </figure>
              <figure
                ref={itemRefs[4]}
                className="animated-badge badge--5 badge-circle circle--3"
              >
                  <img
                      src="/assets/images/elements/dot-orange.png"
                      alt="Doters"
                    />
         
              </figure>
              <figure
                ref={itemRefs[5]}
                className="animated-badge badge--6 badge-circle circle--4"
              >
                 <img
                      src="/assets/images/elements/dot-yellow.png"
                      alt="Doters"
                    />
              </figure>
              <div
                ref={itemRefs[6]}
                className="animated-badge badge--7 badge-surprise"
              >
                <div className="lottie-player">
                  <Lottie options={animationOne} />
                </div>
              </div>
              <div
                ref={itemRefs[7]}
                className={`animated-badge badge--8 badge-tier ${dotersLevel.style}`}
              >
                {dotersMembership.level && (
                  <p>Llegaste a Nivel {`${dotersMembership.level}`}</p>
                )}
              </div>
              <div
                ref={itemRefs[8]}
                className="doters-badge animated-badge badge--doters"
              >
                <figure>
                  <img
                    src={`/assets/images/logo/doters${dotersLevel.image}.svg`}
                    alt="Doters"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneDoters;
