import React, { useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/destiny/04_Postal_playa.json';
import animationDataTwo from '../../../lotties/destiny/01_Postal_internacional.json';
import animationDataFour from '../../../lotties/destiny/03_Postal_nacional.json';
import {
  Destination,
  Preference,
  UserInformation,
} from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface Props {
  customer: Partial<UserInformation>;
}

const SceneDestiny = ({ customer: { preferences } }: Props) => {
  const [messages, setMessages] = React.useState({
    title: '',
    message: '',
    options: {},
  });
  const defaultOptionsOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptionsTwo = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptionsFour = {
    loop: true,
    autoplay: true,
    animationData: animationDataFour,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getDestinationLabels = (destinations: Destination[]) => {
    const beachType = ['Playa'];
    const borderType = ['Latinoamericano', 'US Amigos y Familia', 'Caribe'];

    const isBeachType = destinations.find((d) => beachType.includes(d.type));
    const isBorderType = destinations.find((d) => borderType.includes(d.type));
    if (isBeachType) {
      return {
        title: '¡Eres el sol de México!',
        message: `Disfrutaste del sol y la playa de ${isBeachType.name}`,
        options: defaultOptionsOne,
      };
    }

    if (isBorderType) {
      return {
        title: '¡Cruzando fronteras!',
        message: `Te diste la vuelta por ${isBorderType.name}`,
        options: defaultOptionsTwo,
      };
    }

    return {
      title: `Te diste la vuelta por ${destinations[0].name}`,
      message: '¿Ya sabes a dónde quieres ir el próximo año?',
      options: defaultOptionsFour,
    };
  };

  const getMostRepeatedDestination = (preferences: Preference[]) => {
    if (!preferences) {
      return {
        title: '',
        message: '',
        options: {},
      };
    }

    const destinationCodes: Destination[] = [];
    preferences.forEach((preference) => {
      preference.destinations.forEach((destination) => {
        destinationCodes.push({ ...destination });
      });
    });

    if (!destinationCodes.length)
      return {
        title: '',
        message: '',
        options: {},
      };

    const mostRepeatedDestination = destinationCodes.reduce((prev, current) =>
      prev.code > current.code ? prev : current
    );

    return {
      ...mostRepeatedDestination,
      ...getDestinationLabels([destinationCodes[0]]),
    };
  };

  useEffect(() => {
    const response = getMostRepeatedDestination(preferences || []);
    setMessages({
      title: response.title || '',
      message: response.message || '',
      options: response.options || {},
    });
  }, [preferences]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    itemRefs.forEach((ref, index) => {
      const yPos = index === 0 ? -150 : 50; // ejemplo de diferenciación de posición basado en el índice

      gsap.to(ref.current, {
        scrollTrigger: {
          trigger: triggerRef.current,
          start: 'top center',
          end: 'top 50px',
          scrub: true,
          pinSpacing: false,
          markers: false,
        },
        y: yPos,
        opacity: 1,
        ease: 'easeOut',
        duration: 3,
      });
    });

    return () => {
      // Limpiar todos los triggers creados
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <section className="scene scene--color-2 scene--animated">
        <div ref={triggerRef} className="animation-offset"></div>
        <div className="container">
          <div className="row">
            <div
              ref={itemRefs[0]}
              className="animated-right scene-right col-lg-6 col-12"
            >
              <div className="lottie-player">
                <Lottie options={messages.options as any} />
              </div>
            </div>
            <div
              ref={itemRefs[1]}
              className="animated-left scene-left col-lg-6 col-12"
            >
              <div className="postal postal-back-card">
                <div className="row">
                  <div className="postal-back-card__left col-7">
                    <p className="h4 font-400 mb-0">{`${messages.title}`}</p>
                    <article>
                      <p className="h4 green-text">{`${messages.message}`}</p>
                    </article>
                  </div>
                  <div className="postal-back-card__right col-5">
                    <div className="postal--right__badge">
                      <figure className="badge">
                        <img
                          src="/assets/images/elements/viva-badge.svg"
                          alt="Viva Badge"
                        />
                      </figure>
                    </div>
                    <div className="postal-line">
                      <hr />
                      <hr />
                      <hr />
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneDestiny;
