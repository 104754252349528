import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import helpers from '../../../helpers';
import { UserInformation } from '../../../types/vivaaerobus';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
interface Props {
  customer: Partial<UserInformation>;
}



const SceneEnd = ({ customer }: Props) => {
  const [animationConfig, setAnimationConfig] = useState<any>({
    loop: true,
    autoplay: false,
    animationData: null,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });
  const [imagePath, setImagePath] = useState('');
  const lottieRef = useRef<any>(null);

  useEffect(() => {
    const badges = helpers.calculateBadges(customer);
    setAnimationConfig({
      ...animationConfig,
      animationData: badges.animationData,
    });
    setImagePath(badges.imagePath);
  }, [customer]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
   

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const lottieAnimation = lottieRef.current;
    
     // Aquí se añade la lógica para controlar la animación de Lottie
     ScrollTrigger.create({
      trigger: triggerRef.current,
      start: "top center",
      onEnter: () => {
        if (lottieRef.current) {
          lottieRef.current.play(); // Solo llama a play si lottieRef.current no es null
        }
      },
      onLeaveBack: () => {
        if (lottieRef.current) {
          lottieRef.current.stop(); // Solo llama a stop si lottieRef.current no es null
        }
      },
    });

    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    setTimeout(() => {
      itemRefs.forEach((ref, index) => {
        const scalePos = index === 0 ? 1 : 1; // ejemplo de diferenciación de posición basado en el índice
        const xPos = index === 0 ? '-50%' : 0; // ejemplo de diferenciación de posición basado en el índice
        const yPos = index === 0 ? '-50%' : 0; // ejemplo de diferenciación de posición basado en el índice
        
        gsap.to(ref.current, {
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top center",
            end: 'top 50px',
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
          y: xPos,
          x: yPos,
          scale: scalePos,
          opacity: 1,
          ease: "easeOut",
          duration: 3,
        });
      });

     
  
      return () => {
        // Limpiar todos los triggers creados
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      };
    },1500);
  }, []);

 
  

  return (
    <>
      <section id="scene-badge" className="scene scene--end-badge scene--color-1 scene--animated">
        <div ref={triggerRef}  className="animation-offset"></div>
        <div ref={itemRefs[0]} className="animated-badge-big scene-badge-reward">
          <div className="lottie-player">
            <Lottie options={animationConfig} ref={lottieRef}/>
          </div>
        </div>
        <figure ref={itemRefs[1]} className="animated-badge-back scene-end__back">
          <img src={imagePath} alt="End" />
        </figure>
      </section>
    </>
  );
};
export default SceneEnd;