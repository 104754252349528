import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import animationDataOne from '../../../lotties/39_Ventana.json';
import { Preference, UserInformation } from '../../../types/vivaaerobus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
interface Props {
  customer: Partial<UserInformation>;
}

const ScenePlaceSet = ({ customer: { preferences } }: Props) => {
  const [seatType, setSeatType] = useState({
    body: <></>,
    total: 0,
    type: 'NA',
  });
  const animationOne = {
    loop: true,
    autoplay: true,
    animationData: animationDataOne,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getSeatType = (preferences: Preference[]) => {
    const allSeatTypes: string[] = [];
    preferences?.forEach((preference) => {
      const { seats } = preference;
      allSeatTypes.push(seats.type || 'NA');
    });

    const mostFrequentSeatType = [...allSeatTypes]
      .sort(
        (a, b) =>
          allSeatTypes.filter((v) => v === a).length -
          allSeatTypes.filter((v) => v === b).length
      )
      .pop();

    //console.log({ mostFrequentSeatType, allSeatTypes });
    const mostFrequentSeatTypeCount = allSeatTypes.filter(
      (v) => v === mostFrequentSeatType
    ).length;

    switch (mostFrequentSeatType?.toLocaleLowerCase()) {
      case 'window':
        return {
          body: (
            <>
              <p className="h3 font-400">¡Cielo a la vista!</p>
              <p className="green-text h3">
                {`Disfrutaste de los paisajes en el asiento de la ventanilla.`}
              </p>
            </>
          ),
          total: mostFrequentSeatTypeCount,
          type: 'window',
        };
      case 'aisle':
        return {
          body: (
            <>
              <p className="h3 font-400">
                Ir y venir sin pedir permiso para salir.
              </p>
              <p className="green-text h3">
                {`Disfrutaste de la libertad del asiento del pasillo en la mayoría de tus vuelos.`}
              </p>
            </>
          ),
          total: mostFrequentSeatTypeCount,
          type: 'aisle',
        };
      case 'middle':
        return {
          body: (
            <>
              <p className="h3 font-400">
              Tu asiento preferido es el de en medio
              </p>
              <p className="green-text h3">
                {`Lo tuyo es tener buen chismecito a cada lado.`}
              </p>
            </>
          ),
          total: mostFrequentSeatTypeCount,
          type: 'middle',
        };
      default:
        return {
          body: (
            <>
              <p className="h3 font-400">Ventanilla, pasillo, en medio...</p>
              <p className="green-text h3">
                ¡Cuando se trata de volar, no importa dónde!
              </p>
            </>
          ),
          total: 0,
          type: 'NA',
        };
    }
  };

  useEffect(() => {
    const response = getSeatType(preferences || []);
    setSeatType({
      body: response.body || <></>,
      total: response.total || 0,
      type: response.type || 'NA',
    });
  }, [preferences]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      itemRefs.forEach((ref, index) => {
        const yPos = index === 0 ? 0 : 0; // ejemplo de diferenciación de posición basado en el índice

        gsap.to(ref.current, {
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top center',
            end: 'top 50px',
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
          y: yPos,
          opacity: 1,
          ease: 'easeOut',
          duration: 3,
        });
      });
      //console.log(' ITEM ', itemRefs);

      return () => {
        // Limpiar todos los triggers creados
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }, 1500);
  }, []);

  return (
    <>
      <section className="scene scene--color-2 scene--animated">
        <div ref={triggerRef} className="animation-offset-2 offset-0"></div>
        <div className="container-place-set container">
          <div className="row">
            <div className="row-place-set" data-seat={seatType.type}>
              <div
                ref={itemRefs[0]}
                className="row-animated-place-1 badge-simple badge--seat text-center"
              >
                {seatType.body}
              </div>
              <div
                ref={itemRefs[1]}
                className="row-animated-place-2 row-place__right"
              >
                <div className="place-set__lottie">
                  <div className="lottie-player">
                    <Lottie options={animationOne} />
                  </div>
                </div>
                <div className="chair-system">
                  {seatType.type === 'NA' ? (
                    <>
                      <div className="chair">
                        <div className="chair__badge chair--place1"></div>
                      </div>
                      <div className="chair">
                        <div className="chair__badge chair--place1"></div>
                      </div>
                      <div className="chair">
                        <div className="chair__badge chair--place1"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`chair ${
                          seatType.type === 'window' ? 'active' : ''
                        }`}
                      >
                        <div className="chair__badge chair--place1"></div>
                      </div>
                      <div
                        className={`chair ${
                          seatType.type === 'middle' ? 'active' : ''
                        }`}
                      >
                        <div className="chair__badge chair--place1"></div>
                      </div>
                      <div
                        className={`chair ${
                          seatType.type === 'aisle' ? 'active' : ''
                        }`}
                      >
                        <div className="chair__badge chair--place1"></div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScenePlaceSet;
