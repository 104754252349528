import React, { useEffect, useState, useRef } from 'react';
import { Membership, UserInformation } from '../../../types/vivaaerobus';
import { format } from 'date-fns';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface Props {
  customer: Partial<UserInformation>;
}

const SceneMembership = ({ customer: { membership } }: Props) => {
  const [dotersMembership, setDotersMembership] = useState(
    <article className="member-calendar__article text-center">
      <p>¿Y si el próximo año ganaras Puntos Doters con cada
        uno de tus vuelos?</p>
      <p className="h4 font-700">¡Únete y empieza a ganar!</p>
      
    </article>
  );

  const [vivaFanMembership, setVivaFanMembership] = useState(
    <article className="member-calendar__article text-center">
      <p> Imagina todo lo que hubieses ahorrado si fueras Viva Fan.</p>
      <p className="h4 font-700 bluedark-text">
      ¡Conviértete en <span className="h3 blue-text">Viva Fan</span> y empieza a ahorrar!
      </p>
      
    </article>
  );

  const [hsbcMembership, setHsbcMembership] = useState(
    <article className="member-calendar__article text-center">
      <p>¿Sabías que cada compra con la tarjeta HSBC Viva te hace ganar?</p>
      <p className="h4 font-700">
        Solicita tu tarjeta <span className="h3 red-text">HSBC Viva</span> y
        disfruta de los mejores beneficios.
      </p>
    </article>
  );

  const findDotersMembership = (membership: Membership[]) => {
    const doters = membership.find((m) => m.type === 'Doters');
    if (!doters) return;

    const membershipDate = new Date(doters.start);
    if (!membershipDate) return;

    if (membershipDate.getFullYear() === 2022) {
      setDotersMembership(
        <article className="member-calendar__article text-center">
          <p>¡Tu primer aniversario Doters!</p>

          <p className="h4 font-700">
            En {`${format(membershipDate, 'MMMM')} `}
            cumpliste tu primer año como miembro de{' '}
            <span className="h3 greenLight100-text">Doters</span>. ¡Que sean
            muchos más!
          </p>
        </article>
      );
    } else {
      setDotersMembership(
        <article className="member-calendar__article text-center">
          <p>Un nuevo integrante de la familia Doters.</p>
          <p className="h4 font-700">
            En{' '}
            {`${format(
              membershipDate,
              'MMMM'
            )} te uniste al programa y empezaste a ganar`}{' '}
            <span className="h3 greenLight100-text">Puntos Doters</span>.
          </p>
        </article>
      );
    }
  };

  const findVivaFanMembership = (membership: Membership[]) => {
    const vivaFan = membership.find((m) => m.type === 'VivaFan');
    if (!vivaFan) return;

    const membershipDate = new Date(vivaFan.start.value);
    if (!membershipDate) return;

    const currentYear = new Date().getFullYear();
    if (membershipDate.getFullYear() === 2023) {
      setVivaFanMembership(
        <article className="member-calendar__article text-center">
          <p>Demostrando que eres un verdadero fan.</p>
          <p className="h4 font-700 bluedark-text">
            En {`${format(membershipDate, 'MMMM')} `}
            te convertiste en miembro{' '}
            <span className="h3 blue-text">Viva Fan</span>.
          </p>
        </article>
      );
    } else {
      setVivaFanMembership(
        <article className="member-calendar__article text-center">
          <p>Un año más demostrando que eres fan de volar con Viva.</p>
          <p className="h4 font-700 bluedark-text">
            En {`${format(membershipDate, 'MMMM')} `} cumpliste{' '}
            {`${currentYear - membershipDate.getFullYear()} ${
              currentYear - membershipDate.getFullYear() > 1 ? 'años' : 'año'
            }`}{' '}
            siendo parte de <span className="h3 blue-text">Viva Fan</span>.
          </p>
        </article>
      );
    }
  };

  const findHsbcMembership = (membership: Membership[]) => {
    const hsbc = membership.find((m) => m.type.includes('Cobrand'));
    if (!hsbc || !hsbc.start) return;

    const membershipDate = new Date(hsbc.start.value);
    if (!membershipDate) return;

    setHsbcMembership(
      <article className="member-calendar__article text-center">
        <p>¡Ganando con cada compra!</p>
        <p className="h4 font-700">
          En {`${format(membershipDate, 'MMMM')}`} estrenaste tu Tarjeta de
          Crédito <span className="h3 red-text">HSBC Viva</span> y empezaste a
          ganar.
        </p>
      </article>
    );
  };

  useEffect(() => {
    findDotersMembership(membership || []);
    findVivaFanMembership(membership || []);
    findHsbcMembership(membership || []);
  }, [membership]);

  // GSAP
  const triggerRef = useRef<HTMLDivElement>(null);
  const itemRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null), // Tercera referencia
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 993) return;

    gsap.registerPlugin(ScrollTrigger);
    setTimeout(function () {
      itemRefs.forEach((ref, index) => {
        let animationConfig;
        if (index === 2) {
          // Configuración específica para el tercer elemento
          animationConfig = {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 3,
          };
        } else {
          animationConfig = {
            y: index === 0 ? 0 : 0, // Posición para los primeros dos elementos
            opacity: 1,
            ease: 'easeOut',
            duration: 3,
          };
        }

        gsap.to(ref.current, {
          ...animationConfig,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top center',
            end: 'top 50px',
            scrub: true,
            pinSpacing: false,
            markers: false,
          },
        });
      });

      return () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }, 1500);
  }, []);

  return (
    <>
      <section className="scene scene--color-1 scene--animated">
        <div ref={triggerRef} className="animation-offset offset-0"></div>
        <div className="container">
          <div className="member-row row">
            <div
              ref={itemRefs[0]}
              className="animated-calendar-1 member-calendar"
            >
              <figure className="member-calendar__badge">
                <img
                  src="/assets/images/badges/badge-doters.png"
                  alt="Doters"
                />
              </figure>
              {dotersMembership}
            </div>
            <div
              ref={itemRefs[1]}
              className="animated-calendar-2 member-calendar"
            >
              <figure className="member-calendar__badge">
                <img src="/assets/images/badges/viva-fan.png" alt="Viva Fan" />
              </figure>

              {vivaFanMembership}
            </div>
            <div
              ref={itemRefs[2]}
              className="animated-calendar-3 member-calendar"
            >
              <figure className="member-calendar__badge">
                <img src="/assets/images/badges/badge-hsbc.png" alt="HSBC" />
              </figure>
              {hsbcMembership}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SceneMembership;
