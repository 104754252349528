import React from 'react';
const HeaderComponent = () => {
  return (
    <>
      <header>
        <div className="container">
          <div className="header__inner">
             <a href="https://www.vivaaerobus.com/es-mx/">
                <figure className="header__logo">
                  <img src="/assets/images/logo/viva.svg" alt="Viva" />
                </figure>
             </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderComponent;
